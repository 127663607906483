import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SupportContact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <div className="container primary-bg">
      <div className="row">
        <div className="col">
          <h1 className="small-padding">Support/Contact Us</h1>
          <p className="small-padding">
            The Miami-Dade Beacon Council and its partners invite you to contact
            us with additional questions or feedback. Our goal is to ensure
            BIZHELP.MIAMI provided you with value and satisfactory support.
          </p>
          <a
            className="full-cta"
            href="https://www.beaconcouncil.com/wp-content/uploads/2020/04/FAQ_MDBC_PAYCHECK-PROTECTION-PROGRAM_04.08.2020.pdf"
            target="_blank"
          >
            FAQ
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default SupportContact
